import axios from 'axios'
let base = process.env.VUE_APP_API_BASE_URL
let url = window.location.href.split('?')[1]
let key = new URLSearchParams(url).get('key')
//通用方法
export const POST = (url, params) => {
    if (params) {
        params.token = key
    } else {
        params = {
            token: key
        }
    }

    return axios.post(`${base}${url}`, {
        params: params,
        headers: {
            token: key
        }
    }).then(res => res.data)
}

export const GET = (url, params) => {
    if (params) {
        params.token = key
    } else {
        params = {
            token: key
        }
    }
    return axios.get(`${base}${url}`, {
        params: params,
        headers: {
            token: key
        }
    }).then(res => res.data)
}

export const PUT = (url, params) => {
    if (params) {
        params.token = key
    } else {
        params = {
            token: key
        }
    }
    return axios.put(`${base}${url}`, {
        params: params,
        headers: {
            token: key
        }
    }).then(res => res.data)
}

export const DELETE = (url, params) => {
    if (params) {
        params.token = key
    } else {
        params = {
            token: key
        }
    }
    return axios.delete(`${base}${url}`, {
        params: {
            params: params,
            headers: {
                token: key
            }
        }
    }).then(res => res.data)
}

export const PATCH = (url, params) => {
    if (params) {
        params.token = key
    } else {
        params = {
            token: key
        }
    }
    return axios.patch(`${base}${url}`, {
        params: params,
        headers: {
            token: key
        }
    }).then(res => res.data)
}