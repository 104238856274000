import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    // meta: {
    //   requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
    // },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),

  }
]
const router = new VueRouter({
  mode: "history",
  routes
})
// // 路由守卫
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) { // 需要进行登录验证
//     // 判断session里是否有token
//     let token = sessionStorage.getItem("token")
//     if (token) { // 有token
//       next()
//     } else { // 没有token
//       next('/login')
//     }

//   } else { // 不需要进行登录验证
//     next()
//   }

// })
export default router